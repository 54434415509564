import type { PropsWithChildren } from 'react';
import { Flex } from '@chakra-ui/react';

const StaticLayout = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <Flex
      as="main"
      w="100%"
      h="100%"
      overflowY="auto"
      alignItems="center"
      justifyContent="center"
      py={4}
      sx={{ '@media (max-height: 700px)': { alignItems: 'start' } }}
    >
      {children}
    </Flex>
  );
};

export default StaticLayout;
