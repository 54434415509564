import type { NextPage } from 'next';
import { Box } from '@chakra-ui/react';
import { SignIn } from '@clerk/nextjs';
import StaticLayout from '@layouts/StaticLayout';
import { SIGN_IN_ROUTE } from '@utils/routes/auth';
import { useClerkTheme } from '@utils/theme/clerk';

const SignInPage: NextPage = () => {
  const clerkTheme = useClerkTheme();

  return (
    <StaticLayout>
      <Box sx={clerkTheme}>
        <SignIn
          path={SIGN_IN_ROUTE}
          fallbackRedirectUrl="/app"
          routing="path"
        />
      </Box>
    </StaticLayout>
  );
};

export default SignInPage;
